<script setup lang="ts">
import {
  IMAGE_DEFAULT,
  IMAGE_FIT,
  IMAGE_FORMAT,
  OBJECT_FIT,
} from '../../config/nuxt-responsive-image';
import type {
  Fit,
  ImageFormat,
  Modifier,
  NuxtResponsivePicture,
  Provider,
} from '../../types/nuxt-responsive-picture';

withDefaults(defineProps<{
  alt?: string;
  aspectRatio?: `${number}/${number}`;
  backgroundColorPlaceholder?: string;
  enableLazyLoading?: boolean;
  /**
   * cover: Preserving aspect ratio, ensure the image covers both provided dimensions
   *    by cropping/clipping to fit
   * contain: Preserving aspect ratio, contain within both provided dimensions using
   *    "letterboxing" where necessary.
   * fill: Ignore the aspect ratio of the input and stretch to both provided dimensions.
   * in: Specially for storyblokImages: specifies that the image should not be
   *    auto-cropped but auto-resized (shrunk) to fit inside the imaginary width
   *    and height box instead.
   * inside: Preserving aspect ratio, resize the image to be as large as possible while
   *    ensuring its dimensions are less than or equal to both those specified.
   * outside: Preserving aspect ratio, resize the image to be as small as possible while
   *    ensuring its dimensions are greater than or equal to both those specified.
   */
  fit?: Fit | '';
  forcePlaceholder?: boolean;
  format?: ImageFormat | '';
  /**
   * Modifiers can be used to transform the image dependend on the provider
   * and be applied to the preset
   */
  modifiers?: Modifier;
  /**
   * css property object-fit, used to adopt, how the image should get fit
   * into the parent container
   */
  objectFit?: string;
  /**
   * In case you want to preload the image, use this prop.
   * This will place a corresponding link tag in the page's head.
   */
  preload?: boolean;
  provider: Provider;
  quality?: number;
  responsiveImages?: NuxtResponsivePicture[];
  showLoadingSkeleton?: boolean;
  sizes?: string;
  src?: string;
  width?: number;
}>(), { alt: '',aspectRatio: IMAGE_DEFAULT.ASPECT_RATIO,backgroundColorPlaceholder: '#F4F4F7',enableLazyLoading: true,fit: IMAGE_FIT.COVER,forcePlaceholder: false,format: '',modifiers: {},objectFit: OBJECT_FIT.COVER,preload: false,quality: IMAGE_DEFAULT.QUALITY,responsiveImages: [],sizes: '',src: '',width: 1, })

const emit = defineEmits<{
  error: [];
  loaded: [];
}>();

const hideImageOnError = ref(false);
const isImageLoading = ref<boolean>(true);

const hasMultipleSources = computed(() => __props.responsiveImages.length > 1);

const containerStyle = computed(() => {
  const containerAspectRatio = !hasMultipleSources.value && isImageLoading.value
    ? { aspectRatio: __props.aspectRatio }
    : {};

  return {
    backgroundColor: !isImageLoading.value
      ? IMAGE_DEFAULT.TRANSPARENT
      : __props.backgroundColorPlaceholder,
    ...containerAspectRatio,
  };
});

const fitDependingOnProvider = computed<Fit | ''>(() => {
  if (__props.provider === 'storyblok') {
    return __props.fit === IMAGE_FIT.IN
      ? __props.fit
      : IMAGE_FIT._UNDEFINED;
  }

  return __props.fit;
});

const imageHeight = computed(() => {
  const [
    imageWidth,
    height,
  ] = __props.aspectRatio.split('/');
  if (__props.width && __props.aspectRatio) {
    return (__props.width * parseInt(height, 10)) / parseInt(imageWidth, 10);
  }

  return 0;
});

const formatDependingOnProvider = computed<ImageFormat | ''>(() => {
  // for cloudflare, adding auto will serve the WebP or AVIF format
  // to browsers that support it. If this option is not specified,
  // a standard format like JPEG or PNG will be used.
  if (__props.provider === 'cloudflare') {
    return __props.format || IMAGE_FORMAT._AUTO;
  }

  return __props.format;
});

const getSvgPlaceholderString = (placeholderAspectRatio: string) => {
  const [
    imageWidth,
    height,
  ] = placeholderAspectRatio.split('/');
  return `data:image/svg+xml,<svg
      viewBox="0 0 ${imageWidth} ${height}"
      xmlns="http://www.w3.org/2000/svg"><rect
      fill="${encodeURIComponent('#F4F4F7')}"
      width="${imageWidth}"
      height="${height}"/></svg>`;
};

const placeholderStyle = computed(() => ({
  aspectRatio: __props.aspectRatio,
  backgroundColor: __props.backgroundColorPlaceholder,
}));

const nuxtImageSource = computed(() => {
  if (hasMultipleSources.value) {
    return getSvgPlaceholderString(__props.aspectRatio);
  }
  return __props.src;
});

const onImageLoaded = () => {
  hideImageOnError.value = false;
  isImageLoading.value = false;
  emit('loaded');
};

const onError = () => {
  hideImageOnError.value = true;
  isImageLoading.value = false;
  emit('error');
};
</script>

<template>
  <div
    class="nuxt-picture relative flex overflow-hidden"
    :style="containerStyle"
  >
    <div
      v-if="forcePlaceholder || hideImageOnError || (!nuxtImageSource && !responsiveImages.length)"
      class="nuxt-picture__placeholder w-full"
      :style="placeholderStyle"
    />
    <picture
      v-else
      class="nuxt-picture__picture flex"
      :class="[ objectFit === OBJECT_FIT.COVER ? 'size-full' : 'max-h-full max-w-full' ]"
    >
      <NuxtSource
        v-for="(image, index) in responsiveImages"
        :key="`${image.srcset}${index}`"
        :format="formatDependingOnProvider"
        :media="image.media || ''"
        :preload="preload"
        :provider="provider"
        :quality="quality"
        :sizes="image.sizesNuxtImage"
        :src="image.srcset"
      />
      <NuxtImage
        class="nuxt-picture__image"
        :alt="alt"
        :enable-lazy-loading="enableLazyLoading"
        :fit="fitDependingOnProvider"
        :format="formatDependingOnProvider"
        :height="imageHeight"
        :image-src="nuxtImageSource"
        :modifiers="modifiers"
        :object-fit="objectFit"
        :preload="preload && responsiveImages.length == 0"
        :provider="provider"
        :quality="quality"
        :sizes="sizes"
        :width="width"
        @error="onError"
        @loaded="onImageLoaded"
      />
    </picture>
    <UiSkeleton
      v-if="src && showLoadingSkeleton && isImageLoading"
      class="visible absolute top-0 size-full"
    />
  </div>
</template>
